import { Link } from "react-router-dom";
import _, { set } from "lodash";
import clsx from "clsx";

import { useTranslation } from "react-i18next";
import { IconCog, IconSpinner } from "../components/icons";
import { Commodity } from "../products/products";
import { useRoles } from "../user/Auth";
import { Income, useIncome } from "./IncomeProvider";
import { useRef, useState } from "react";
import Modal from "../components/Modal";
import { Field, Form, Formik } from "formik";
import { FlagIcon, CalendarIcon } from "@heroicons/react/20/solid";
import { PlusIcon as FatPlusIcon } from "@heroicons/react/16/solid"
import { countryCodes, supportedCurrencies } from "../data";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { useSingleTeamMangement } from "../Management";

export default function IncomeProductView({commodity}: {commodity: Commodity}) {
    const {t} = useTranslation()
    const {isAdmin} = useRoles()
    const {team} = useSingleTeamMangement()

    const {incomeLookup} = useIncome()

    const incomes = incomeLookup[commodity.commodityId] ?? {}

    const countries = _(incomes).keys().sortBy().value()
    const years = _(incomes).values().flatMap(_.keys).uniq().sortBy().reverse().map(k => +k).value()

    return <>
        <div className="flex flex-row gap-2">
            <h3>{t('income')} ({team?.currency ?? 'EUR'})</h3>
            {isAdmin && <Link to="/settings/team"><IconCog className="size-6 text-slate-300 hover:text-pcx-400" /></Link>}
        </div>
        <div className="w-fit pt-4">
            <div className="flex flex-row-reverse my-2">
                <IncomeAddButton {...{commodity, countries, years}} />
            </div>
            {_.size(incomes) > 0 &&
                <table>
                    <thead>
                        <tr className="border-b-2 border-pcx-300">
                            <th className={clsx(headerClass, 'text-left')}>{t('country')}</th>
                            {years.map(year => <th key={year} className={clsx(headerClass, 'text-right')}>{year}</th>)}
                            {/* <th className="pt-1 font-normal"><AddEntryButton {...{...commodity, year}} /></th> */}
                        </tr>
                    </thead>
                    <tfoot>
                        {countries.map(country =>
                            <tr key={country} className="border-b-2 border-pcx-200 last:border-pcx-300">
                                <td className="md:pl-3 pr-3">{country}</td>
                                {years.map(year =>
                                    <td key={year} className="text-right">
                                        <TableCell {...{ income: incomes[country]?.[year] ?? {year, countryCode: country, commodityId: commodity.commodityId} }} />
                                    </td>)}
                            </tr>)}
                    </tfoot>
                </table>}
        </div>

    </>
}
// create a formatter for numbers
const formatter = new Intl.NumberFormat()

const parts = formatter.formatToParts(12345678.69)
const groupSymbol = parts.find(part => part.type === "group")?.value || "";
const decimalSymbol = parts.find(part => part.type === "decimal")?.value || ".";
const groupRegex = new RegExp(`[${groupSymbol}]`, "g");

function cleanFormattedNumber(value: string): number {
    return parseFloat(value.replace(groupRegex, "").replace(decimalSymbol, "."))
}

function TableCell({income}: {income?: Omit<Income, 'amount' | 'currency'> & {amount?: number, currency?: string}}) {
    const ref = useRef(null)
    const {postIncome, deleteIncome} = useIncome()
    // const [isFocused, setIsFocused] = useState(false)
    const [amount, setAmount] = useState(income?.amount ?? '-')
    const [currency, setCurrency] = useState(income?.currency)
    const defaultCurrency = useSingleTeamMangement().team?.currency ?? 'EUR'

    function onInput(e) {
        setAmount(e.target.innerText)
    }

    async function onBlur() {
        await onBlurAction(currency ?? defaultCurrency)
    }
    async function onBlurAction(_currency: string) {
        const _amount = typeof amount === 'number' ? amount : cleanFormattedNumber(amount)
        // console.log({amount, _amount})
        if (!isNaN(_amount) && _amount !== 0) {
            //console.log('currency is now ' + _currency)
            await postIncome({...income, amount: _amount, currency: _currency})
            ref.current.textContent = formatter.format(_amount)
        } else {
            setAmount(undefined)
            if (income?.incomeId !== undefined && income?.commodityId !== undefined) {
                // @ts-ignore
                deleteIncome(income)
            }
            ref.current.textContent = '-'
        }
    }

    const hasAmount = income?.amount !== undefined || typeof amount === 'number' || !isNaN(cleanFormattedNumber(amount ?? ''))

    async function onCurrencyChange(e) {
        const newCurrency = e.target.value
        //console.log('currency changed to ' + newCurrency)
        setCurrency(newCurrency)
        await onBlurAction(newCurrency)
    }


    function onKeyDown(e) {
        if (e.key === "Enter") {
            e.preventDefault(); // Prevent newline in the editable div
            ref.current.blur(); // Remove focus from the div and trigger blur logic
        }
    };

    return (
        <div  className="p-px flex flex-row w-full justify-end gap-0">
            <div ref={ref}
                contentEditable {...{onBlur, onInput, onKeyDown}} 
                suppressContentEditableWarning={true} // Suppresses React's warning for using contentEditable
                className={clsx("border border-white rounded-sm hover:border-slate-300", commonCellStyle, !hasAmount && 'text-slate-300 focus:text-black')}
            >
                {income?.amount ? formatter.format(income.amount) : '-'}
            </div>
            <select value={currency ?? defaultCurrency}
                onChange={onCurrencyChange}
                className={clsx(
                    "px-1 bg-white border border-white rounded-sm hover:border-slate-300 bg-none appearance-none",
                    hasAmount ? "opacity-100" : "opacity-20"
                )}
            >
                {supportedCurrencies.map(ccy => <option key={ccy} value={ccy}>{ccy}</option>)}
            </select>
        </div>
    )
}

const commonCellStyle = "tabular-nums text-right px-2"


const headerClass = "px-2.5 py-2 text-pcx-600 text-sm font-semibold uppercase tracking-wider whitespace-nowrap"

function AddEntryModal({commodityId, year, close}: {commodityId: number, year: number, close: () => void}) {
    const {t} = useTranslation()
    const {team} = useSingleTeamMangement()

    const {postIncome} = useIncome()

    const initialValues = {
        countryCode: 'WO',
        amount: 0.0,
        currency: team?.currency ?? 'EUR',
        year,
    }

    async function onSubmit(values) {
        await postIncome({...values, commodityId})
        close()
    }

    return (
        <Modal escAction={close}>
            <Formik {...{initialValues, onSubmit}}>
                <Form>
                    <div className="p-4 text-left grid sm:grid-cols-[12rem_8rem] gap-3">
                        <h4 className="sm:col-span-2">Add Income</h4>

                        <label className="block">
                            <div className="label mb-1">{t('country')}</div>
                            <Field className="form-select w-full" name="countryCode" as="select" >
                                {countryCodes.map(value => <option key={value} value={value}>{value}</option>)}
                            </Field>
                        </label>

                        <label className="block">
                            <div className="label mb-1">{t('year')}</div>
                            <Field className="form-input w-full" type="number" name="year" />
                        </label>

                        <label className="block">
                            <div className="label mb-1">{t('amount')}</div>
                            <Field className="form-input w-full" type="number" name="amount" autoFocus />
                        </label>

                        <label className="block">
                            <div className="label mb-1">{t('currency')}</div>
                            <Field className="form-select w-full" name="currency" as="select" >
                                {supportedCurrencies.map(ccy => <option key={ccy} value={ccy}>{ccy}</option>)}
                            </Field>
                        </label>

                    </div>
                    <div className="bg-pcx-200 flex flex-row-reverse gap-4 p-4">
                        <input type="submit" value={t("add")} className="btn-primary" />
                        <button onClick={close} className="btn-secondary" type="button">
                            {t("cancel")}
                        </button>
                    </div>
                </Form>
            </Formik>
        </Modal>
    )
}

type AddAction = 'add' | 'copy-year' | 'copy-country'

function IncomeAddButton(
    {commodity, countries, years}:
    {commodity: Commodity, countries: string[], years: number[]}) 
{
    const {t} = useTranslation()

    const [action, setAction] = useState(undefined as AddAction | undefined)

    const close = () => setAction(undefined)
    const isEmpty = countries.length === 0 || years.length === 0

    //console.log({years})
    const year = new Date().getFullYear()

    const actions = [
        { label: t('add-entry'), action: 'add', icon: <FatPlusIcon className="size-4" /> },
        { label: t('copy-year'), action: 'copy-year', icon: <CalendarIcon className="size-4" /> },
        { label: t('copy-country'), action: 'copy-country', icon: <FlagIcon className="size-4" /> },
    ] as { label: string, action: AddAction, icon: JSX.Element, disabled?: boolean }[]

    if (isEmpty) {
        return <>
            <button onClick={() => setAction('add')} className="flex flex-row gap-4 p-2 rounded-xl hover:bg-gray-50">
                <div className="bg-pcx-400 p-4 rounded-lg shrink-0">
                    <PencilSquareIcon className="size-8 text-white" />
                </div>
                <div className="text-left">
                    <h4 className="text-sm">{t('add-entry')}</h4>
                    <p className="text-sm text-gray-500 mt-1">
                        {t('manually-add-entry')}
                    </p>
                </div>
            </button>
            {action === 'add'
                ? <AddEntryModal {...{ ...commodity, year, close }} />
                : null}
        </>
    } else {
        return <>
            <Menu>
                <MenuButton className="flex flex-row gap-1 py-px px-1 pr-2 items-center btn-secondary text-sm">
                    <FatPlusIcon className="size-4" /> {t('add')}
                </MenuButton>
                <MenuItems anchor="bottom end" className="mt-1 bg-white rounded-md border border-pcx-300 shadow-lg">
                    {actions.map(({ label, action, icon, disabled }) =>
                        <MenuItem key={action}>
                            <button
                                disabled={disabled} onClick={() => setAction(action)}
                                className="flex flex-row gap-2 items-center px-2 py-1 group hover:bg-pcx-100 w-full"
                            >
                                <span className="text-pcx-400 group-hover:text-pcx-600 group-disabled:text-slate-200">{icon}</span>
                                <span className="text-slate-600 group-hover:text-slate-800 group-disabled:text-slate-300">{label}</span>
                            </button>
                        </MenuItem>)}
                </MenuItems>
            </Menu>
            {action === 'add'
                ? <AddEntryModal {...{ ...commodity, year, close }} />
                : action === 'copy-country'
                    ? <CopyCountryModal {...{ commodity, countries, close }} />
                    : action === 'copy-year'
                        ? <CopyYearModal {...{ commodity, years, close }} />
                        : null}
        </>
    }
}

function CopyCountryModal({commodity, close, countries}) {
    const {t} = useTranslation()

    const {incomeLookup, postIncome} = useIncome()

    const remaining = countryCodes.filter(code => !countries.includes(code))
    const initialValues = { from: countries[0], to: remaining[0] }

    const incomes = incomeLookup[commodity.commodityId] ?? {}

    async function onSubmit({from, to}) {
        const toCopy = _(incomes[from] ?? {})
            .values()
            .map(value => ({...value, countryCode: to, incomeId: undefined}))
            .value()
        await Promise.all(toCopy.map(postIncome))
        close()
    }

    return (
        <Modal escAction={close}>
            <Formik {...{initialValues, onSubmit}}>{({isSubmitting}) =>
                <Form>
                    <div className="p-4 flex flex-col gap-3 sm:min-w-xs text-left">
                        <h4>{t('copy-country')}</h4>
                        <label>
                            <div className="label mb-1">{t('copy-from')}</div>
                            <Field className="form-select w-full" name="from" as="select">
                                {countries.map(value => <option key={value} value={value}>{value}</option>)}
                            </Field>
                        </label>
                        <label>
                            <div className="label mb-1">{t('country')}</div>
                            <Field className="form-select w-full" name="to" as="select">
                                {remaining.map(value => <option key={value} value={value}>{value}</option>)}
                            </Field>
                        </label>
                    </div>
                    <div className="bg-pcx-200 flex flex-row-reverse gap-4 p-4">
                        <button type="submit" className="btn-primary flex flex-row gap-2 items-center" disabled={isSubmitting}>
                            {t("copy")} {isSubmitting && <IconSpinner className="animate-spin size-4" />}
                        </button> 
                        <button onClick={close} className="btn-secondary" type="button">
                            {t("cancel")}
                        </button>
                    </div>
                </Form>
            }</Formik>
        </Modal>
    )
}

function findGoodYear(years: number[]): number {
    let year = new Date().getFullYear()
    while (years.includes(year)) {
        year--
    }
    return year
}

function CopyYearModal({commodity, close, years}) {
    const {t} = useTranslation()

    const initialValues = { from: years[0], to: findGoodYear(years) }
    const {incomeLookup, postIncome} = useIncome()

    async function onSubmit({from, to}) {
        const toCopy = _(incomeLookup[commodity.commodityId] ?? {})
            .values()
            .map(_is => _is[from])
            .map(value => ({...value, year: to, incomeId: undefined}))
            .value()
        await Promise.all(toCopy.map(postIncome))
        close()
    }

    function validate(values) {
        const errors = {}
        const to = parseInt(values.to)
        if (isNaN(to) || !to) {
            errors['to'] = 'Must be a number.'
        } else if (years.includes(to)) {
            errors['to'] = 'Year already exists.'
        }
        return errors
    }

    return (
        <Modal escAction={close}>
            <Formik {...{initialValues, onSubmit, validate}}>{({isSubmitting, touched, errors}) =>
                <Form>
                    <div className="p-4 flex flex-col gap-3 sm:min-w-xs text-left">
                        <h4>{t('copy-year')}</h4>
                        <label>
                            <div className="label mb-1">{t('copy-from')}</div>
                            <Field className="form-select w-full" name="from" as="select">
                                {years.map(value => <option key={value} value={value}>{value}</option>)}
                            </Field>
                        </label>
                        <label>
                            <div className="label mb-1">
                                {/* @ts-ignore */}
                                {t('year')} {touched.to && errors.to && <span className="text-red-500"> - {errors.to}</span>}
                            </div>
                            <Field className="form-input w-full" name="to" type="number" />
                        </label>
                    </div>
                    <div className="bg-pcx-200 flex flex-row-reverse gap-4 p-4">
                        <button type="submit" className="btn-primary flex flex-row gap-2 items-center" disabled={isSubmitting}>
                            {t('copy')} {isSubmitting && <IconSpinner className="animate-spin size-4" />}
                        </button> 
                        <button onClick={close} className="btn-secondary" type="button">
                            {t('cancel')}
                        </button>
                    </div>
                </Form>
            }</Formik>
        </Modal>
    )
}